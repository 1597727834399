import { gql } from '@apollo/client'

const GET_MERCHANT_PRODUCTS = gql`
  query getMerchantProducts(
    $merchantSlug: String!
    $isActive: Boolean! = true
  ) {
    products(
      where: {
        merchant: { slug: { _eq: $merchantSlug } }
        archived_at: { _is_null: $isActive }
      }
      order_by: { inserted_at: desc }
    ) {
      id
      name
      description
      seo_description
      sku
      source_id
      default_variant_id
      default_vat
      default_limit
      inserted_at
      archived_at
      publish_to_stores
      allergens
      availabilities {
        id
        product_id
        modifier_id
        fulfillment_type
        is_available
        inserted_at
        updated_at
      }
      default_variant: product_variants(
        where: { default_variant: { _eq: true } }
      ) {
        id
        price
        vat
        price_with_vat
        sku
        source_id
        alcoholic: restrictions(path: "alcoholic")
        calorie_data
        image
        additional_images {
          id
          url: image
        }
      }
    }
  }
`

const GET_MERCHANT_CATEGORIES = gql`
  query getMerchantCategories($merchantId: uuid!, $active: Boolean = true) {
    categories(
      where: {
        merchant_id: { _eq: $merchantId }
        archived_at: { _is_null: $active }
      }
      order_by: { name: asc }
    ) {
      id
      name
      description
      slug
    }
  }
`

const GET_ALL_MERCHANT_CATEGORIES = gql`
  query getAllMerchantCategories($merchantId: uuid!) {
    categories(
      where: { merchant_id: { _eq: $merchantId } }
      order_by: { name: asc }
    ) {
      id
      name
      archived_at
    }
  }
`

const GET_PRODUCTS_MULTIPLE_CATEGORIES = gql`
  query getProductsMultipleCategories($productId: uuid) {
    products_categories(
      where: {
        product_id: { _eq: $productId }
        category: { archived_at: { _is_null: true } }
      }
    ) {
      id
      product_id
      category_id
      category {
        name
      }
    }
  }
`

const GET_PRODUCTS_CATEGORIES = gql`
  query getProductsMultipleCategories(
    $productIds: [products_categories_bool_exp]
  ) {
    products_categories(where: { _or: $productIds }) {
      id
      product_id
      category_id
      category {
        name
      }
    }
  }
`

const GET_PRODUCTS_CATEGORIES_BY_CATEGORY_IDS = gql`
  query getProductsCategoriesByCategoryIds(
    $categoryIds: [products_categories_bool_exp]
  ) {
    products_categories(where: { _or: $categoryIds }) {
      id
      product_id
      category_id
      category {
        name
      }
    }
  }
`

const buildProductsCategoriesByCategoryIds = (active?: boolean) => {
  const isActive = active ? `product: { archived_at: { _is_null: true } }` : ``

  return gql`
  query getProductsCategoriesByCategoryIds(
    $categoryIds: [products_categories_bool_exp]
  ) {
    products_categories(
      where: {
        _or: $categoryIds
        ${isActive}
      }
    ) {
      category_id
      product {
        id
        default_variant_id
      }
    }
  }
`
}

const CREATE_PRODUCTS_CATEGORIES_ENTRY = gql`
  mutation insertProductMultipleCategories(
    $productCategories: [products_categories_insert_input!]!
  ) {
    insert_products_categories(objects: $productCategories) {
      returning {
        product_id
        category_id
      }
    }
  }
`

const DELETE_PRODUCTS_CATEGORIES_ENTRY = gql`
  mutation deleteProductCategory(
    $productCategoryIds: [products_categories_bool_exp]!
  ) {
    delete_products_categories(where: { _or: $productCategoryIds }) {
      returning {
        id
      }
    }
  }
`

const CREATE_PRODUCT = gql`
  mutation createProduct(
    $name: String!
    $sku: String
    $source_id: String!
    $description: String
    $seo_description: String
    $price: Decimal!
    $price_with_vat: Decimal!
    $default_vat: Decimal!
    $default_limit: Int
    $publish_to_stores: Boolean
    $restrictions: RestrictionMapParams
    $allergens: [String]!
    $calorieData: CalorieDataParams
    $category_ids: [String]!
    $fulfillment_types: [String]
  ) {
    createProduct(
      name: $name
      description: $description
      seoDescription: $seo_description
      categoryIds: $category_ids
      sku: $sku
      price: $price
      price_with_vat: $price_with_vat
      sourceId: $source_id
      defaultVat: $default_vat
      defaultLimit: $default_limit
      published: $publish_to_stores
      allergens: $allergens
      calorieData: $calorieData
      restrictions: $restrictions
      fulfillmentTypes: $fulfillment_types
    ) {
      name
      default_variant_id
    }
  }
`

const CREATE_STORE_VARIANTS = gql`
  mutation createStoreVariants($values: [store_variants_insert_input!]!) {
    insert_store_variants(objects: $values) {
      affected_rows
    }
  }
`

const UPDATE_MERCHANT_ARRANGEMENT_SETTINGS = gql`
  mutation updateMerchantProductArrangment(
    $merchant: String!
    $arrangement: jsonb
  ) {
    update_merchants(
      where: { slug: { _eq: $merchant } }
      _append: { setting: $arrangement }
    ) {
      affected_rows
    }
  }
`

const GET_STORE_IDS = gql`
  query queryStores($merchantSlug: String!) {
    stores(where: { merchant: { slug: { _eq: $merchantSlug } } }) {
      id
    }
  }
`

const GET_MERCHANT_SETTINGS = gql`
  query getMerchantSettings($merchantSlug: String!) {
    merchants(where: { slug: { _eq: $merchantSlug } }) {
      setting
    }
  }
`

const PRODUCT_SLUG_CHANGE_LOG = gql`
  mutation productSlugChange(
    $productId: ID!
    $oldSlug: String!
    $newSlug: String!
  ) {
    productSlugChange(
      productId: $productId
      oldSlug: $oldSlug
      newSlug: $newSlug
    )
  }
`

const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $product_id: ID!
    $name: String!
    $price: Decimal!
    $default_vat: Decimal!
    $sku: String
    $source_id: String!
    $description: String
    $seo_description: String
    $default_limit: Int!
    $category_ids: [String]!
    $price_with_vat: Decimal!
    $publish_to_stores: Boolean
    $restrictions: RestrictionMapParams
    $allergens: [String]!
    $calorieData: CalorieDataParams
    $availabilities: [AvailabilityParams]
  ) {
    updateProduct(
      productId: $product_id
      name: $name
      description: $description
      seoDescription: $seo_description
      categoryIds: $category_ids
      sku: $sku
      price: $price
      sourceId: $source_id
      price_with_vat: $price_with_vat
      defaultVat: $default_vat
      defaultLimit: $default_limit
      publishToStores: $publish_to_stores
      allergens: $allergens
      calorieData: $calorieData
      restrictions: $restrictions
      availabilities: $availabilities
    ) {
      name
    }
  }
`

const UPDATE_PRODUCT_DEFAULT_VAT = gql`
  mutation updateProductDefaultVat($id: uuid!, $default_vat: numeric!) {
    update_products(
      where: { id: { _eq: $id } }
      _set: { default_vat: $default_vat }
    ) {
      affected_rows
    }
  }
`

const TOGGLE_PRODUCT_ARCHIVE_STATE = gql`
  mutation toggleProductArchiveState(
    $productIds: [uuid!]
    $archivedAt: timestamp
  ) {
    update_products(
      where: { id: { _in: $productIds } }
      _set: { archived_at: $archivedAt, source_id: null }
    ) {
      affected_rows
    }

    update_product_variants(
      where: { product_id: { _in: $productIds } }
      _set: { archived_at: $archivedAt, source_id: null }
    ) {
      affected_rows
    }
  }
`

const ARCHIVE_PRODUCT = gql`
  mutation archiveProduct($productId: ID!) {
    archiveProduct(productId: $productId)
  }
`

const UNARCHIVE_PRODUCT = gql`
  mutation unarchiveProduct($productId: ID!) {
    unarchiveProduct(productId: $productId)
  }
`

const DUPLICATE_PRODUCT = gql`
  mutation duplicateProduct($productId: ID!) {
    duplicateProduct(productId: $productId) {
      id
      name
    }
  }
`

const UPDATE_CATEGORY = gql`
  mutation updateCategory(
    $merchantSlug: String!
    $id: String!
    $name: String!
    $description: String
  ) {
    updateCategory(
      merchantSlug: $merchantSlug
      id: $id
      name: $name
      description: $description
    ) {
      id
    }
  }
`

const TOGGLE_CATEGORY_ARCHIVE_STATE = gql`
  mutation archiveRestoreCategory($categoryId: uuid!, $archivedAt: timestamp) {
    update_categories(
      where: { id: { _eq: $categoryId } }
      _set: { archived_at: $archivedAt, source_id: null }
    ) {
      affected_rows
    }
  }
`

const CREATE_CATEGORY = gql`
  mutation createCategory(
    $merchantSlug: String!
    $name: String!
    $description: String
  ) {
    createCategory(
      merchantSlug: $merchantSlug
      name: $name
      description: $description
    ) {
      id
      name
      slug
      description
    }
  }
`

const VIEW_CATEGORY_PRODUCT_COUNT = gql`
  query viewCategoryProductCount($merchantId: uuid!) {
    view_category_product_count(where: { merchant_id: { _eq: $merchantId } }) {
      category_id
      product_count
    }
  }
`

const GET_MERCHANT_PRODUCT_VARIANTS = gql`
  query getMerchantProductVariants(
    $merchantId: uuid!
    $productId: uuid!
    $active: Boolean = true
  ) {
    product_variants(
      where: {
        merchant_id: { _eq: $merchantId }
        product_id: { _eq: $productId }
        archived_at: { _is_null: $active }
      }
      order_by: { name: asc }
    ) {
      id
      archived_at
      default_variant
      image
      inserted_at
      updated_at
      limit
      name
      options
      price
      price_with_vat
      quantity
      restrictions
      sku
      source_id
      vat
      calorie_data
      options_allergens
      store_variants {
        store_id
        preorder_store_id
        published_at
      }
      product {
        name
        publish_to_stores
      }
      pricings(where: { store_variant_id: { _is_null: true } }) {
        id
        variant_id
        fulfillment_type
        price
        price_with_vat
        vat
        published_at
      }
    }
  }
`

const GET_ARCHIVED_PRODUCT_OPTIONS = gql`
  query getArchivedProductOptions($productId: ID!) {
    getArchivedProductOptions(productId: $productId) {
      name
      values
    }
  }
`

const RESTORE_OPTION = gql`
  mutation restoreProductOptions(
    $productId: ID!
    $optionName: String!
    $optionValue: String!
  ) {
    restoreVariantOption(
      productId: $productId
      optionName: $optionName
      optionValue: $optionValue
    ) {
      name
    }
  }
`

const GET_MERCHANT_STORES = gql`
  query queryStores($merchantId: uuid!) {
    stores(where: { merchant_id: { _eq: $merchantId } }) {
      id
      name
      slug
    }
  }
`

const CREATE_PRODUCT_VARIANTS = gql`
  mutation createProductVariants($values: [product_variants_insert_input!]!) {
    insert_product_variants(objects: $values) {
      affected_rows
    }
  }
`

const ADD_PRODUCT_OPTIONS = gql`
  mutation addProductOptions($productId: ID!, $options: [OptionParams]) {
    addOptions(productId: $productId, options: $options) {
      id
    }
  }
`

const UPDATE_PRODUCT_VARIANT = gql`
  mutation updateProductVariant(
    $productId: uuid!
    $sku: String
    $price: numeric
    $vat: numeric
    $limit: Int
    $restrictions: jsonb
    $calorieData: jsonb
  ) {
    update_product_variants(
      where: { id: { _eq: $productId } }
      _set: {
        sku: $sku
        price: $price
        vat: $vat
        limit: $limit
        restrictions: $restrictions
        updated_at: "now()"
        calorie_data: $calorieData
      }
    ) {
      affected_rows
    }
  }
`

const TOGGLE_PRODUCT_VARIANT_ARCHIVE_STATE = gql`
  mutation toggleProductVariantArchiveState(
    $productVariantId: uuid!
    $archived_at: timestamp
  ) {
    update_product_variants(
      where: { id: { _eq: $productVariantId } }
      _set: { archived_at: $archived_at, source_id: null }
    ) {
      affected_rows
    }
  }
`

const TOGGLE_PRODUCT_ADD_TO_INVENTORY_STATE = gql`
  mutation toggleProductAddToInventoryState(
    $merchantId: uuid!
    $productId: uuid!
    $publishedAt: timestamp
  ) {
    update_store_variants(
      where: {
        product_variant: {
          merchant_id: { _eq: $merchantId }
          product_id: { _eq: $productId }
        }
      }
      _set: { published_at: $publishedAt, updated_at: "now()" }
    ) {
      affected_rows
    }
  }
`

const buildMerchantModifierGroups = (getActive: boolean = true) => {
  const archiveAtFilter = getActive ? `archived_at: { _is_null: true }` : ``

  return gql`
    query GetMerchantModifierGroups(
      $merchantId: uuid!
    ) {
      modifier_groups(
        where: {
          merchant_id: {
            _eq: $merchantId
          },
          ${archiveAtFilter}
        }
      ) {
        id
        name
        description
        sku
        minimum_enabled
        minimum
        maximum_enabled
        maximum
        archived_at
        modifier_arrangement
        modifier_group_modifiers {
          modifier {
            id
            name
          }
        }
      }
    }
  `
}

const GET_PRODUCT_MODIFIER_GROUP = gql`
  query GetMerchantModifierGroups($productId: uuid!) {
    modifier_groups(
      where: {
        product_modifier_groups: { product_id: { _eq: $productId } }
        archived_at: { _is_null: true }
      }
    ) {
      id
    }
    products(where: { id: { _eq: $productId } }) {
      modifier_group_arrangement
    }
  }
`

const CREATE_PRODUCT_MODIFIER_GROUPS = gql`
  mutation createMerchantProductModifierGroups(
    $values: [product_modifier_groups_insert_input!]!
    $productId: uuid
    $modifierGroupArrangement: jsonb
  ) {
    insert_product_modifier_groups(objects: $values) {
      affected_rows
    }

    update_products(
      where: { id: { _eq: $productId } }
      _set: { modifier_group_arrangement: $modifierGroupArrangement }
    ) {
      affected_rows
    }
  }
`
const UPDATE_PRODUCT_MODIFIER_GROUP_ARRANGEMENTS = gql`
  mutation updateProductModifierGroupArrangements(
    $productId: uuid!
    $arrangement: jsonb
  ) {
    update_products(
      where: { id: { _eq: $productId } }
      _set: { modifier_group_arrangement: $arrangement, updated_at: "now()" }
    ) {
      affected_rows
    }
  }
`

const REMOVE_MODIFIER_GROUP_FROM_PRODUCT = gql`
  mutation removeModifierGroupFromProduct(
    $productId: uuid!
    $modifierGroupId: uuid!
    $arrangement: jsonb
  ) {
    delete_product_modifier_groups(
      where: {
        product_id: { _eq: $productId }
        modifier_group_id: { _eq: $modifierGroupId }
      }
    ) {
      affected_rows
    }

    update_products(
      where: { id: { _eq: $productId } }
      _set: { modifier_group_arrangement: $arrangement, updated_at: "now()" }
    ) {
      affected_rows
    }
  }
`

const UPLOAD_PRODUCT_IMAGE = gql`
  mutation uploadProductVariantImage($variantId: ID!, $image: Upload!) {
    uploadProductVariantImage(
      variantId: $variantId
      image: $image
      override: true
    ) {
      id
      additional_images {
        id
        url
      }
    }
  }
`

// Meh. Absinthe, you suck with multipart.
const UPLOAD_SINGLE_ADDITIONAL_PRODUCT_IMAGE = gql`
  mutation uploadSingleProductVariantImage($variantId: ID!, $img1: Upload!) {
    uploadProductVariantImages(variantId: $variantId, img1: $img1) {
      name
      image
      additional_images {
        id
        url
      }
    }
  }
`

const UPLOAD_ADDITIONAL_PRODUCT_IMAGES = gql`
  mutation uploadProductVariantImages(
    $variantId: ID!
    $img1: Upload!
    $img2: Upload
    $img3: Upload
    $img4: Upload
  ) {
    uploadProductVariantImages(
      variantId: $variantId
      img1: $img1
      img2: $img2
      img3: $img3
      img4: $img4
    ) {
      name
      image
      additional_images {
        id
        url
      }
    }
  }
`

const REMOVE_IMAGE = gql`
  mutation deleteAdditionalImage($imageId: String!) {
    deleteAdditionalImage(imageId: $imageId) {
      id
    }
  }
`

const UPSERT_VARIANT_AVAILABILITIES = gql`
  mutation upsertVariantAvailabilities(
    $values: [availabilities_insert_input!]!
  ) {
    insert_availabilities(
      objects: $values
      on_conflict: {
        constraint: availabilities_pkey
        update_columns: [is_available, updated_at]
      }
    ) {
      affected_rows
    }
  }
`

const GET_PRODUCT_DETAILS = gql`
  query getProductDetails($productId: uuid!) {
    products_by_pk(id: $productId) {
      id
      name
      options
      options_allergens
    }
  }
`

const ARCHIVE_PRODUCT_VARIANTS = gql`
  mutation archiveVariants($variantIds: [uuid!]) {
    update_product_variants(
      where: { id: { _in: $variantIds } }
      _set: { archived_at: "now()", source_id: null }
    ) {
      affected_rows
    }
  }
`

const GENERATE_PRODUCT_SEO = gql`
  mutation generateProductSeo(
    $merchantId: ID!
    $name: String
    $description: String
  ) {
    generateProductSeo(
      merchantId: $merchantId
      name: $name
      description: $description
    ) {
      seo_description
    }
  }
`

export {
  GENERATE_PRODUCT_SEO,
  GET_MERCHANT_SETTINGS,
  GET_MERCHANT_PRODUCTS,
  GET_MERCHANT_CATEGORIES,
  GET_STORE_IDS,
  CREATE_PRODUCT,
  CREATE_STORE_VARIANTS,
  UPDATE_MERCHANT_ARRANGEMENT_SETTINGS,
  UPDATE_PRODUCT,
  TOGGLE_PRODUCT_ARCHIVE_STATE,
  DUPLICATE_PRODUCT,
  UPDATE_CATEGORY,
  TOGGLE_CATEGORY_ARCHIVE_STATE,
  CREATE_CATEGORY,
  VIEW_CATEGORY_PRODUCT_COUNT,
  GET_MERCHANT_PRODUCT_VARIANTS,
  GET_ARCHIVED_PRODUCT_OPTIONS,
  RESTORE_OPTION,
  GET_MERCHANT_STORES,
  CREATE_PRODUCT_VARIANTS,
  ADD_PRODUCT_OPTIONS,
  UPDATE_PRODUCT_VARIANT,
  GET_PRODUCT_MODIFIER_GROUP,
  CREATE_PRODUCT_MODIFIER_GROUPS,
  UPDATE_PRODUCT_MODIFIER_GROUP_ARRANGEMENTS,
  REMOVE_MODIFIER_GROUP_FROM_PRODUCT,
  UPLOAD_PRODUCT_IMAGE,
  UPLOAD_ADDITIONAL_PRODUCT_IMAGES,
  UPLOAD_SINGLE_ADDITIONAL_PRODUCT_IMAGE,
  REMOVE_IMAGE,
  TOGGLE_PRODUCT_VARIANT_ARCHIVE_STATE,
  TOGGLE_PRODUCT_ADD_TO_INVENTORY_STATE,
  buildMerchantModifierGroups,
  UPSERT_VARIANT_AVAILABILITIES,
  GET_PRODUCT_DETAILS,
  ARCHIVE_PRODUCT_VARIANTS,
  GET_PRODUCTS_MULTIPLE_CATEGORIES,
  CREATE_PRODUCTS_CATEGORIES_ENTRY,
  DELETE_PRODUCTS_CATEGORIES_ENTRY,
  GET_PRODUCTS_CATEGORIES,
  GET_PRODUCTS_CATEGORIES_BY_CATEGORY_IDS,
  buildProductsCategoriesByCategoryIds,
  GET_ALL_MERCHANT_CATEGORIES,
  PRODUCT_SLUG_CHANGE_LOG,
  ARCHIVE_PRODUCT,
  UNARCHIVE_PRODUCT,
  UPDATE_PRODUCT_DEFAULT_VAT
}
