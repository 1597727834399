import React, { useState } from 'react'
import {
  Form,
  Input,
  Button,
  PageHeader,
  message,
  Divider,
  Tooltip,
  Row,
  Col
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { SettingProps } from '@slerp/controls'
import { useMutation, useApolloClient } from '@apollo/client'
import {
  UPDATE_GENERAL_SETTINGS,
  CREATE_COMPANY_ADDRESS,
  UPDATE_COMPANY_ADDRESS
} from './actions'
import styled from '@emotion/styled'
import AddressInput from './../Locations/LocationForm/AddressInput'
import { isEmpty } from 'lodash'
import { uuid } from 'uuidv4'
import { isValidUKPhoneNumber } from './../Locations/LocationForm/helpers'
import { companySuffixRule, companyRegisteredNumberRule } from './Rules'
import MobileAppStoreInput from './MobileAppStoreInput'
import { handleCompanyWebsiteFormat } from 'components/Login/utils'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'

type AddressValueType = {
  validateTrigger: boolean
  postalCode: string
  formattedAddress: string
}

export const UKPhoneValidatorRule = () => ({
  async validator(_: any, value: string) {
    if (!value) {
      return Promise.reject('Contact number is required')
    }

    if (isValidUKPhoneNumber('+44' + value)) {
      return Promise.resolve()
    }

    return Promise.reject('Please input a valid UK phone number')
  }
})

const { useForm } = Form

const GeneralSettings = (props: SettingProps) => {
  const { merchant } = props
  const [form] = useForm()
  const [hasValidExistingAddress, setHasValidExistingAddress] = useState(
    !!(
      (merchant?.address?.line_1 || merchant?.address?.line_2) &&
      merchant?.address?.country &&
      merchant?.address?.zip
    )
  )
  const [updateSetting, { loading, error }] = useMutation(
    UPDATE_GENERAL_SETTINGS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const values = form.getFieldsValue()
        const { company_phone_number, registered_company_address } = values

        const hasNoCompanyAddress = isEmpty(merchant.address)

        //guard clause to only do a mutation if there is a valid input on registered_company_address
        if (
          hasNoCompanyAddress &&
          !registered_company_address.formattedAddress &&
          !company_phone_number
        )
          return

        const companyAddressMutation = hasNoCompanyAddress
          ? createCompanyAddress
          : updateCompanyAddress

        companyAddressMutation({
          variables: {
            ...(hasNoCompanyAddress && { id: uuid() }),
            merchant_id: merchant.id,
            line_1: registered_company_address.line1,
            line_2: registered_company_address.line2,
            city: registered_company_address.city,
            country: registered_company_address.country,
            zip: registered_company_address.postalCode,
            company_phone_number: `+44${company_phone_number}`
          }
        })
          .then((result) => {
            message.destroy()
            message.success(
              'Company Information has been successfully updated!',
              2
            )
          })
          .catch((error) =>
            message.error(
              `Unable to update company information due to ${
                hasNoCompanyAddress
                  ? errorCreateCompanyAddress
                  : errorUpdateCompanyAddress
              }.`,
              5
            )
          )
          .finally(() => client.resetStore())
      }
    }
  )

  const [
    createCompanyAddress,
    { loading: loadingCreateCompanyAddress, error: errorCreateCompanyAddress }
  ] = useMutation(CREATE_COMPANY_ADDRESS, {
    fetchPolicy: 'no-cache'
  })

  const [
    updateCompanyAddress,
    { loading: loadingUpdateCompanyAddress, error: errorUpdateCompanyAddress }
  ] = useMutation(UPDATE_COMPANY_ADDRESS, {
    fetchPolicy: 'no-cache'
  })

  const client = useApolloClient()

  const onFinish = (values: any) => {
    const {
      company_name,
      company_email,
      company_number,
      vat_id,
      email,
      website,
      name,
      registered_company_name,
      cro_registered_number
    } = values

    const formattedUrl = handleCompanyWebsiteFormat(website)

    form
      .validateFields()
      .then(() => {
        message.loading('Updating... Please wait.')
        updateSetting({
          variables: {
            id: merchant.id,
            settings: {
              company_name,
              company_email,
              company_number,
              vat_id,
              email,
              website: formattedUrl
            },
            name: name,
            registered_company_name: registered_company_name,
            cro_registered_number: cro_registered_number
          }
        })
          .then((result) => {
            message.destroy()
            message.success('Merchant Updated', 2)
          })
          .catch((error) =>
            message.error(`Unable to update settings due to ${error}.`, 5)
          )
      })
      .catch((error) =>
        message.error(`Unable to update settings due to ${error}.`, 5)
      )
  }

  const addressValidatorRule = (slug: string) => ({
    async validator(_: any, value: AddressValueType) {
      if (!value || !value.validateTrigger) {
        // omits address validity check if there's already an existing valid address
        if (hasValidExistingAddress) return
        return Promise.reject('Please select an address from the suggestions')
      }
    }
  })

  return (
    <>
      <Form
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        form={form}
        layout={'vertical'}
        onFinish={onFinish}
        initialValues={{
          name: merchant.name,
          email: merchant.setting.email,
          website: merchant.setting.website,
          slug: merchant.slug,
          company_email: merchant.setting.company_email,
          vat_id: merchant.setting.vat_id,
          registered_company_name: merchant.registered_company_name,
          cro_registered_number: merchant.cro_registered_number,
          company_phone_number: merchant.address?.contact_num.substring(3),
          apple_store_url: merchant.app_settings[0].apple_store_url,
          google_play_url: merchant.app_settings[0].google_play_url,
          registered_company_address: {
            formattedAddress: merchant.address
              ? [
                  merchant.address?.line_1,
                  merchant.address?.line_2,
                  merchant.address?.city,
                  merchant.address?.zip
                ]
                  .filter(Boolean)
                  .join(', ')
              : '',
            line1: merchant.address?.line_1 || '',
            line2: merchant.address?.line_2 || '',
            city: merchant.address?.city || '',
            country: merchant.address?.country || '',
            postalCode: merchant.address?.zip || ''
          }
        }}
      >
        <PageHeader title='General Settings' />

        <Form.Item name='name' label='Company Name:'>
          <Input required data-testid='general-settings-company-name-field' />
        </Form.Item>

        <Form.Item
          name='email'
          label={
            <>
              Email:
              <Tooltip
                title={
                  <span>
                    Ensure this is the best email to use to receive Slerp news
                    and updates.
                  </span>
                }
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
          rules={[
            {
              type: 'email',
              message: 'Please input a valid email'
            }
          ]}
        >
          <Input data-testid='general-settings-email-field' />
        </Form.Item>

        <Form.Item name='website' label='Website:'>
          <Input data-testid='general-settings-website-field' />
        </Form.Item>

        <Form.Item
          name='slug'
          label={
            <>
              SlerpSpace Domain:
              <Tooltip
                title={
                  <span>
                    Talk to a member of our support team if you wish to change.
                  </span>
                }
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
        >
          <Input
            prefix='https://'
            suffix='.slerp.com'
            disabled
            data-testid='slerpspace-domain-field'
          />
        </Form.Item>

        {merchant.app_settings[0].apple_store_url && (
          <MobileAppStoreInput
            label='Apple Store'
            tooltipTitle='This is the URL for your app that customers will use to download the app from the App Store'
            type='apple_store_url'
          />
        )}

        {merchant.app_settings[0].google_play_url && (
          <MobileAppStoreInput
            label='Google Play'
            tooltipTitle='This is the URL for your app that customers will use to download the app from Google Play'
            type='google_play_url'
          />
        )}

        <Divider />

        <PageHeader
          title={
            <>
              Trading Details
              <Tooltip
                title={
                  <span>
                    Make sure all trading information has been provided, as we
                    will use the information to ensure that customer receipts
                    are valid.
                  </span>
                }
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
        />

        <Form.Item
          name='registered_company_name'
          label='Registered Company Name:'
          rules={companySuffixRule}
        >
          <Input data-testid='registered-company-name-field' />
        </Form.Item>

        <Form.Item
          name='registered_company_address'
          label='Registered Company Address:'
          rules={[addressValidatorRule(merchant.slug)]}
        >
          <AddressInput onChange={() => setHasValidExistingAddress(false)} />
        </Form.Item>

        <Form.Item label='Company Phone Number:'>
          <Input.Group>
            <Row gutter={8} className='_mb-0'>
              <Col span={5}>
                <Input defaultValue='+44' disabled />
              </Col>
              <Col span={19}>
                <Form.Item
                  name='company_phone_number'
                  noStyle
                  rules={[UKPhoneValidatorRule]}
                >
                  <Input
                    placeholder='7911123456'
                    type='number'
                    data-testid='company-phone-number-field'
                    onFocus={onFocusScrollNumber}
                    onBlur={onBlurScrollNumber}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
        </Form.Item>

        <Form.Item
          name='cro_registered_number'
          label='Company Registration Number:'
          rules={companyRegisteredNumberRule}
        >
          <Input data-testid='company-registration-number-field' />
        </Form.Item>

        <Form.Item
          name='company_email'
          label={
            <>
              Accounts Email:
              <Tooltip
                title={
                  <span>
                    This email address will receive invoices and payment-related
                    notifications
                  </span>
                }
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
          rules={[
            {
              required: true,
              message: 'Email is required'
            },
            {
              type: 'email',
              message: 'Please input a valid email'
            }
          ]}
        >
          <Input data-testid='accounts-email-field' />
        </Form.Item>

        <Form.Item name='vat_id' label='Vat ID:'>
          <Input data-testid='vat-id-field' />
        </Form.Item>

        <Form.Item>
          <Submit>
            <Button
              data-testid='general-settings-save-btn'
              title='Save'
              type='primary'
              size='large'
              htmlType='submit'
              className='_ml-8'
              loading={
                loading ||
                loadingUpdateCompanyAddress ||
                loadingCreateCompanyAddress
              }
            >
              Save
            </Button>
          </Submit>
        </Form.Item>
      </Form>
    </>
  )
}

const Submit = styled.div(() => ({
  textAlign: 'right'
}))

export default GeneralSettings
