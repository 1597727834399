import { gql } from '@apollo/client'

const QUERY_MERCHANT_SETTING = gql`
  query getMerchant($id: uuid!) {
    merchant: merchants_by_pk(id: $id) {
      id
      name
      description
      slug
      setting
      vehicles
      pre_order_vehicles
      trust_pilot_email
      registered_company_name
      cro_registered_number
      subscription
      stripe_settings {
        stripe_user_id
      }
      app_settings {
        google_play_url
        apple_store_url
      }
      address {
        merchant_id
        contact_num
        line_1
        line_2
        city
        country
        zip
      }
      current_plan
      zero_order_rate_id
    }
  }
`

const QUERY_MERCHANT_IS_SEO_ENABLED = gql`
  query getMerchantIsSeoEnabled($id: uuid!) {
    merchant: merchants_by_pk(id: $id) {
      id
      is_seo_enabled
    }
  }
`

const QUERY_MERCHANT_STATS = gql`
  query queryMerchantStats($slug: String!) {
    merchants(where: { slug: { _eq: $slug } }) {
      id
      name
      published_at
      orders_aggregate {
        aggregate {
          count(columns: id)
          sum {
            subtotal
            total
          }
        }
      }
      pending_orders: orders_aggregate(where: { status: { _eq: "pending" } }) {
        aggregate {
          count(columns: id)
        }
      }
      same_day_orders: orders_aggregate(
        where: { fulfillment_date: { _is_null: true } }
      ) {
        aggregate {
          count(columns: id)
          sum {
            subtotal
          }
        }
      }
      pre_orders: orders_aggregate(
        where: { fulfillment_date: { _is_null: false } }
      ) {
        aggregate {
          count(columns: id)
          sum {
            subtotal
          }
        }
      }
      pickup_orders: orders_aggregate(
        where: { fulfillment_type: { _eq: "pickup" } }
      ) {
        aggregate {
          count(columns: id)
          sum {
            subtotal
          }
        }
      }
      delivery_orders: orders_aggregate(
        where: { fulfillment_type: { _eq: "delivery" } }
      ) {
        aggregate {
          count(columns: id)
          sum {
            subtotal
          }
        }
      }
      open_stores: stores_aggregate(
        where: { is_open: { _eq: true }, archived_at: { _is_null: true } }
      ) {
        aggregate {
          count
        }
      }
      total_stores: stores_aggregate(
        where: { archived_at: { _is_null: true } }
      ) {
        aggregate {
          count
        }
      }
      customers: customers_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

const QUERY_MERCHANT_DISCOUNTS = gql`
  query merchantDiscounts($merchantId: uuid!) {
    discounts(where: { merchant_id: { _eq: $merchantId } }) {
      id
      code
      trigger
      target
      type
      description
      value
      quantity
      start_date
      end_date
      minimum_value
      published_at
      store_ids
      customer_reward_id
    }
    discount_usages: merchants(where: { id: { _eq: $merchantId } }) {
      discount_usages {
        discount_id
        count
      }
    }
  }
`

const QUERY_MERCHANT_CUSTOMERS = gql`
  query merchantCustomers($merchantId: uuid!) {
    orders(
      limit: 100
      where: {
        merchant_id: { _eq: $merchantId }
        details_disclosed: { _eq: true }
      }
      order_by: { inserted_at: desc }
    ) {
      first_name: customer_details(path: "first_name")
      last_name: customer_details(path: "last_name")
      email: customer_details(path: "email")
      contact_number: customer_details(path: "contact_num")
      zip: customer_details(path: "address.zip")
      city: customer_details(path: "address.city")
      address: customer_details(path: "address.line_2")
    }
    customers(
      limit: 100
      where: { merchant_id: { _eq: $merchantId } }
      order_by: { inserted_at: desc }
    ) {
      merchant_id
      first_name
      last_name
      email
      birthdate
      inserted_at
      stamps: stamps_aggregate {
        aggregate {
          count
        }
      }
      redeemed_cards: customer_loyalty_cards_aggregate(
        where: { redeemed_at: { _is_null: false } }
      ) {
        aggregate {
          count
        }
      }
      orders: orders_aggregate {
        aggregate {
          sum {
            total
          }
        }
      }
    }
  }
`

const QUERY_MERCHANT_CONNECTED_CARDS = gql`
  query merchantConnectedStripeCards($merchantSlug: String) {
    cards: merchantConnectedStripeCards(merchantSlug: $merchantSlug) {
      id
      last4
      expMonth
      expYear
      defaultSource
    }
  }
`

const QUERY_MERCHANT_STRIPE_ACCOUNT_SESSION = gql`
  query getMerchantStripeAccountSession($merchantSlug: String!) {
    getMerchantStripeAccountSession(merchantSlug: $merchantSlug) {
      account
      clientSecret
      publicKey
      expiresAt
      livemode
      object
    }
  }
`

export {
  QUERY_MERCHANT_SETTING,
  QUERY_MERCHANT_STATS,
  QUERY_MERCHANT_DISCOUNTS,
  QUERY_MERCHANT_CUSTOMERS,
  QUERY_MERCHANT_CONNECTED_CARDS,
  QUERY_MERCHANT_IS_SEO_ENABLED,
  QUERY_MERCHANT_STRIPE_ACCOUNT_SESSION
}
